<template>
  <v-card class="pa-4">
    <!-- <div v-for="(item, i) in item" :key="i"> -->
    <!-- new modified -->
    <v-toolbar width="100%" class="mx-0" color="primary " dark>
      <v-card-text class="text-h5"> Notice Board Update</v-card-text>
      <v-spacer> </v-spacer>
      <v-btn icon @click="$emit('closeDialog')">
        <v-icon large color="red lighten-2">mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-row>
      <v-col cols="12" sm="6">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                :items="rooms"
                item-text="room_name"
                item-value="room_id"
                v-model="notice.rooms"
                label="Room"
                multiple
              />
              <!-- <v-combobox
            v-model="notice.room"
            label="Select Class"
            :items="rooms"
            item-value="name"
            item-text="name"
            multiple
            chips
          ></v-combobox> -->

              <!-- <v-combobox
            v-model="notice.room"
            label="Select Class"
            :items="classes.map((item) => item.id)"
            item-value="name"
            item-text="name"
            multiple
            chips
          ></v-combobox> -->

              <v-text-field
                v-model="notice.title"
                :rules="$requiredRules"
                label="Title"
              ></v-text-field>

              <v-textarea
                v-model="notice.content"
                :rules="$requiredRules"
                label="Content"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row> </v-row>
    <v-card-actions>
      <v-spacer> </v-spacer>
      <v-btn color="primary" outlined @click="update">Update</v-btn>
    </v-card-actions>
    <!-- </div> -->
  </v-card>
</template>

<script>
export default {
  props: ["item"],

  emits: ["closeDialog", "reFreshData"],

  data: () => ({
    updateClose: false,
    notice: {
      title: "",
      content: "",
      items: [],
    },
    noticeData: [],
    rooms: [],
    room: "",
  }),
  mounted() {
    this.loadOwnedRooms();
    this.loadNotice();
  },
  // async created() {
  //   await this.loadNotice();
  // },
  //  mounted() {
  //    this.loadNotice();
  // },

  computed: {
    classes() {
      return this.$store.state.classes.classes;
    },
  },
  // mounted() {
  //     this.loadRooms();
  // },
  methods: {
    loadOwnedRooms() {
      this.$api.get("/class_rooms/").then((res) => {
        this.rooms = res.data.results;
      });
    },
    loadNotice() {
      this.$api
        .get(`/notice/${this.item.id}`)
        .then((response) => {
          this.notice = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // loadRooms() {

    //       this.$api.get(`/rooms/${this.item.rooms}/`).then((res) => {
    //           this.items = res.data;
    //       }).catch((err) => {
    //           console.log(err)
    //       })
    //   },

    update() {
      this.$api.put(`/notice/${this.item.id}/`, this.notice).then((res) => {
        this.notice = res.data;
        this.$emit("closeDialog");
        this.$emit("reFreshData");
      });

      // const currentRoute = this.$router.currentRoute.path;
      // const targetRoute = "/organization/notice/list/";

      // if (currentRoute !== targetRoute) {
      //   this.$router.push(targetRoute);
      // }
    },
  },
};
</script>

<style></style>
